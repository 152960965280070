import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  fetchListofNFTStart,
  deleteSingleNFTStart,
  paginateListOfNFTStart,
  searchNFTImageStart,
  sortListOfNFTStart,
} from "../store/actions/NFTActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import Web3 from "web3";
import { apiConstants } from "../Constant/constants";
import NftLoot from "../../abis/KeysToTheMetaverse.json";
import MintNowModal from "./MintNowModal";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import {
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@material-ui/core";
import CustomSelect from "../Helper/CustomSelect";
import { Alert } from "react-bootstrap";
import HDWalletProvider from "@truffle/hdwallet-provider";

//Declare IPFS
const auth =
  "Basic " +
  Buffer.from(
    apiConstants.ipfs_project_id + ":" + apiConstants.ipfs_project_secret
  ).toString("base64");
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
}); // leaving out the arguments will default to these values

// Declare IPFS json
const ipfsJson = require("nano-ipfs-store").at("https://ipfs.infura.io:5001");

const ListOfNFT = (props) => {
  useEffect(() => {
    props.dispatch(fetchListofNFTStart({ skip: 0, take: 10 }));
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const [pageCount, setPageCount] = useState(0);

  const [deleteNFT, setDeleteNFT] = useState(null);

  const [skipInitialRender, setSkipInitialRender] = useState(false);

  const history = useHistory();

  const [nftLoot, setNftLoot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenURIs, setTokenURLs] = useState([]);
  const [data, setData] = useState([]);
  const [totalSupply, setTotalSupply] = useState("");
  const [startFetching, setStartFetching] = useState(false);
  const [fetchCount, setFetchCount] = useState(3);
  const [initCount, setInitCount] = useState(0);
  const [noMoreData, setNoMoreData] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [loadMoreContent, setLoadMoreContent] = useState("");
  const [connectMetaMask, setConnectMetaMask] = useState(false);
  const [account, setAccount] = useState("");
  const [ethBalance, setEthBalance] = useState("");
  const [minterWalletAddress, setMinterWalletAdddress] = useState("");
  const [mintButtonContent, setMintButtonContent] = useState("");
  const [inputData, setInputData] = useState({
    title: "",
    description: "",
  });
  const [images, setImages] = useState([]);
  const [img, setImg] = useState("");
  const [nftWords, setNftWords] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [mintNowModal, setMintNowModal] = useState(false);

  const [mintNowinputData, setMintNowInputData] = useState({
    wallet_address: "",
  });

  const [searchInputData, setSearchInputData] = useState({
    searchKey: "",
    searchStatus: false,
  });

  const [sortby, setSortby] = useState(null);

  const [selecteditems, setSelectedItems] = useState([]);

  // My account.

  const [myAccount, setMyAccount] = useState("");


  const connectingMetaMask = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else {
      setConnectMetaMask(false);
      const notificationMessage = getErrorNotificationMessage(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
  };

  const getWalletAddress = async () => {
    const web3 = window.web3;
    try {
      // Network ID
      const networkId = await web3.eth.net.getId();
      console.log("Not", networkId);
      const networkData = NftLoot.networks[networkId];
      console.log("Net if", networkData);
      // if (networkData) {
      const nftLoot = new web3.eth.Contract(
        NftLoot.abi,
        // configuration.get("configData.contract_address")
        "0xc5445e288C31Cf3DA16B4f20e75a01170bCB4a93"
      );
      setNftLoot(nftLoot);
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);

      const ethBalance = await web3.eth.getBalance(accounts[0]);
      setEthBalance(ethBalance);
      setConnectMetaMask(true);
      // } else {
      //   setConnectMetaMask(false);
      //   const notificationMessage = getErrorNotificationMessage(
      //     "Contract not deployed to detected network."
      //   );
      //   props.dispatch(createNotification(notificationMessage));
      // }
    } catch (error) {
      console.log("error", error);
      setConnectMetaMask(false);
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };

  // Generate metadata json file.
  const generateJson = async (data) => {
    const metadata = JSON.stringify({
      description: data.description,
      external_url: "https://keystometaverse.com",
      image: "ipfs://" + data.imageHash,
      name: data.name,
      text: data.allWords,
      attributes: [
        {
          trait_type: "Type",
          value: data.type,
        },
        {
          trait_type: "Material",
          value: data.material,
        },
        {
          trait_type: "Key Color",
          value: data.key_color,
        },
        {
          trait_type: "Bow",
          value: data.bow,
        },
        {
          trait_type: "Chain",
          value: data.chain,
        },
        {
          trait_type: "Closed Ward Shapes",
          value: data.closed_ward_shapes,
        },
        {
          trait_type: "Collar Type",
          value: data.collar_type,
        },
        {
          trait_type: "Extra",
          value: data.extra,
        },
        {
          trait_type: "Eye Color",
          value: data.eye_color,
        },
        {
          trait_type: "Key Bit Type",
          value: data.key_bit_type,
        },
        {
          trait_type: "Key Color",
          value: data.key_color,
        },
        {
          trait_type: "KeyFob",
          value: data.key_fob,
        },
        {
          trait_type: "RN Bit Pattern",
          value: data.rn_bit_pattern,
        },
        {
          trait_type: "Side Cut Wards",
          value: data.side_cut_wards,
        },
        {
          trait_type: "Stem",
          value: data.stem,
        },
        {
          trait_type: "Tip",
          value: data.tip,
        },
        {
          trait_type: "Background",
          value: data.background,
        },
      ],
    });
    console.log("Json", metadata);
    return metadata;
  };

  const mintYourNFT = async (event) => {
    event.preventDefault();
    console.log("current data", currentData);
    setMintButtonContent("Initiated...");
    if (nftLoot != null) {
      // fetch data from api.
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ color: colorName, mouth: mouthName, eye: eyeName, picture: image })
      };
      try {
        setMintButtonContent((prevState) => "Connecting to Blockchain");

        let imageData = await convertDataURIToBinaryFF(
          "data:image/png;base64," + currentData.picture_base
        );

        imageData = Buffer(imageData);

        let allWords =
          currentData.nft_properties.type +
          " " +
          currentData.nft_properties.material +
          " " +
          currentData.nft_properties.key_color +
          " " +
          currentData.nft_properties.bow +
          " " +
          currentData.nft_properties.chain +
          " " +
          currentData.nft_properties.closed_ward_shapes +
          " " +
          currentData.nft_properties.collar_type +
          " " +
          currentData.nft_properties.extra +
          " " +
          currentData.nft_properties.eye_color +
          " " +
          currentData.nft_properties.key_bit_type +
          " " +
          currentData.nft_properties.key_fob +
          " " +
          currentData.nft_properties.rn_bit_pattern +
          " " +
          currentData.nft_properties.side_cut_wards +
          " " +
          currentData.nft_properties.stem +
          " " +
          currentData.nft_properties.tip +
          " " +
          currentData.nft_properties.background;

        //adding file to the IPFS

        ipfs.add(imageData, async (error, result) => {
          console.log("Ipfs result", result);
          if (error) {
            console.error(error);
            return;
          }
          setImg(result[0].hash);

          const json = generateJson({
            name: currentData.name,
            description: currentData.description,
            imageHash: result[0].hash,
            type: currentData.nft_properties.type,
            material: currentData.nft_properties.material,
            key_color: currentData.nft_properties.key_color,
            bow: currentData.nft_properties.bow,
            chain: currentData.nft_properties.chain,
            closed_ward_shapes: currentData.nft_properties.closed_ward_shapes,
            collar_type: currentData.nft_properties.collar_type,
            extra: currentData.nft_properties.extra,
            eye_color: currentData.nft_properties.eye_color,
            key_bit_type: currentData.nft_properties.key_bit_type,
            key_fob: currentData.nft_properties.key_fob,
            rn_bit_pattern: currentData.nft_properties.rn_bit_pattern,
            side_cut_wards: currentData.nft_properties.side_cut_wards,
            stem: currentData.nft_properties.stem,
            tip: currentData.nft_properties.tip,
            background: currentData.nft_properties.background,
            allWords: allWords,
          }).then(async (val) => {
            try {
              val = Buffer(val);
              const cidArr = await ipfs.add(val);
              const cid = cidArr[0].hash;
              // const cid = await ipfsJson.add(val);
              console.log("minter address", minterWalletAddress);
              console.log("all words", allWords);
              nftLoot.methods
                .publicMinting(
                  minterWalletAddress,
                  cid,
                  allWords
                )
                .send({ from: account })
                .on("error", (error) => {
                  let notificationMessage;
                  if (error.message == undefined) {
                    notificationMessage = getErrorNotificationMessage(
                      "Same Wallet can't have more than 2 NFT! Use different wallet address"
                    );
                  } else {
                    notificationMessage = getErrorNotificationMessage(
                      error.message
                    );
                  }
                  props.dispatch(createNotification(notificationMessage));
                  setMintButtonContent("");
                  handleMintNowModalClose();
                })
                .once("receipt", (receipt) => {
                  setLoading(false);
                  setMintButtonContent("");
                  setImages((prevState) => [...prevState, result[0].hash]);
                  setNftWords((prevState) => [...prevState, allWords]);
                  sendTransactionApi(
                    receipt.transactionHash,
                    currentData.nft_image_id
                  );
                });
            } catch (error) {
              const notificationMessage = getErrorNotificationMessage(
                "Invalid wallet address"
              );
              props.dispatch(createNotification(notificationMessage));
              setMintButtonContent("");
              handleMintNowModalClose();
            }
          });
        });
      } catch (error) {
        console.log("Error", error);
        setMintButtonContent("");
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        props.dispatch(createNotification(notificationMessage));
      }
    } else {
      setMintButtonContent("");
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const sendTransactionApi = (transactionHash, nft_image_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        transaction_id: transactionHash,
        wallet_address: minterWalletAddress,
        nft_image_id: nft_image_id,
      }),
    };
    try {
      fetch(
        "https://cms-ktmnftmint.blockstall.com/api/user/nft_images_complete",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            const notificationMessage = getSuccessNotificationMessage(
              "Minted Successfully"
            );
            props.dispatch(createNotification(notificationMessage));
            // window.location.reload();
            handleMintNowModalClose();
          } else {
            const notificationMessage = getErrorNotificationMessage(
              "Saving data error.."
            );
            props.dispatch(createNotification(notificationMessage));
          }
        });
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong. Please refresh the page and try again."
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
    setSelectedItems([]);
    setCurrentData([]);
    if (!searchInputData.searchStatus) {
      props.dispatch(
        paginateListOfNFTStart({
          skip: selected * PER_PAGE,
          take: PER_PAGE,
        })
      );
    }
  };

  useEffect(() => {
    if (!props.listOFNFT.loading) {
      if (sortby) {
        setPageCount(Math.ceil(props.listOFNFT.data.length / PER_PAGE));
      } else {
        setPageCount(Math.ceil(props.listOFNFT.total / PER_PAGE));
      }
    }
  }, [props.listOFNFT.data]);

  const handleNFTDelete = (id) => {
    setDeleteNFT(id);
  };

  useEffect(() => {
    if (skipInitialRender) {
      props.dispatch(deleteSingleNFTStart({ nft_image_id: deleteNFT }));
    }
    setSkipInitialRender(true);
  }, [deleteNFT]);

  // useEffect(() => {
  //   if (!props.deleteSingleNFT.loading) {
  //     props.dispatch(fetchListofNFTStart());
  //   }
  // }, [props.deleteSingleNFT.data]);

  const handleMintNowModalClose = () => {
    setMintNowModal(false);
    setMintNowInputData({
      ...mintNowinputData,
      wallet_address: "",
    });
    setMinterWalletAdddress("");
  };

  const handleMintModalShow = (data) => {
    if (connectMetaMask) {
      setCurrentData((prevState) => data);
      setMintNowModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Connect your Wallet, Before mint"
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  useEffect(() => {
    if (skipInitialRender && searchInputData.searchKey != "") {
      const timer = setTimeout(() => {
        props.dispatch(
          searchNFTImageStart({ search_key: searchInputData.searchKey })
        );
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [searchInputData.searchKey]);

  const handleSearchInputClear = () => {
    setSearchInputData({
      ...searchInputData,
      searchKey: "",
      searchStatus: false,
    });
    props.dispatch(
      paginateListOfNFTStart({
        skip: 0,
        take: PER_PAGE,
      })
    );
    setCurrentPage(0);
  };

  const handleNFTSort = (sort_by) => {
    setSortby(sort_by);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (skipInitialRender) {
      props.dispatch(sortListOfNFTStart({ status: sortby }));
    }
  }, [sortby]);

  const handleSingleItemSelect = (id) => {
    if (selecteditems.length > 0) {
      const isExists = selecteditems.find((data) => data == id);
      if (isExists == id) {
        const newArray = selecteditems.filter((data) => data != id);
        setSelectedItems(newArray);
        setCurrentData(newArray);
      } else {
        setSelectedItems([...selecteditems, id]);
        setCurrentData([...currentData, id]);
      }
    } else {
      setSelectedItems([...selecteditems, id]);
      setCurrentData([...currentData, id]);
    }
  };

  const handleBulkItemSelect = () => {
    if (selecteditems.length > 0) {
      setSelectedItems([]);
      setCurrentData([]);
    } else {
      const bulkSelect = props.listOFNFT.data
        .slice(offset, offset + PER_PAGE)
        .map((data, index) => {
          return data;
        });

      setSelectedItems(bulkSelect);
      setCurrentData(bulkSelect);
    }
  };

  return (
    <>
      <main className="h-full overflow-y-auto">
        <div className="mx-auto grid">
          <div className="w-full overflow-hidden rounded-lg shadow-xs ">
            {props.listOFNFT.loading ? (
              "loading"
            ) : (
              <div className="content-wrapper">
                <div className="content-section">
                  <div className="apps-card w-100">
                    <div className="app-card w-100 p-0">
                      <div className="list_nft_table_header flex flex-col md:flex-row">
                        <div className="text-left p-3 flex-1">
                          <div className="w-100">
                            <div className="relative  focus-within:text-purple-500">
                              <div className="absolute inset-y-0 flex items-center pl-2">
                                <svg
                                  className="w-4 h-4"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                              </div>
                              <div className="search-custom-input">
                                <input
                                  className="w-100 "
                                  type="text"
                                  placeholder="Search for NFT Images"
                                  aria-label="Search"
                                  value={searchInputData.searchKey}
                                  onChange={(event) =>
                                    setSearchInputData({
                                      ...searchInputData,
                                      searchKey: event.target.value,
                                      searchStatus: true,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="m-auto">
                          {searchInputData.searchKey != "" && (
                            <button
                              className=" flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-full active:bg-red-600 hover:bg-red-600 focus:outline-none focus:shadow-outline-purple"
                              onClick={() => handleSearchInputClear()}
                            >
                              <svg
                                fill="#fff"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                              </svg>
                            </button>
                          )}
                        </div>
                        <div className="text-right p-3  flex-1 flex-grow">
                          <button
                            onClick={connectingMetaMask}
                            className="content-button status-button"
                          >
                            {connectMetaMask ? account : "Connect Wallet"}
                          </button>
                        </div>
                      </div>
                      <div className="list-of-nft-header2 px-3 pb-3">
                        <div className="sortby-wrapper">
                          {/* <p>Sort by:</p>
                    <button
                      onClick={() => handleNFTSort(1)}
                      className=" px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    >
                      Created
                    </button>

                    <button
                      onClick={() => handleNFTSort(2)}
                      className=" px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    >
                      Initiated
                    </button>

                    <button
                      onClick={() => handleNFTSort(3)}
                      className=" px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    >
                      Completed
                    </button> */}
                          <CustomSelect
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={sortby}
                            label="Sort By"
                            onChange={(event) =>
                              handleNFTSort(event.target.value)
                            }
                            selected={sortby}
                          >
                            {/* <MenuItem value={""}>Clear</MenuItem> */}
                            <MenuItem value={1}>Created</MenuItem>
                            <MenuItem value={2}>Initiated</MenuItem>
                            <MenuItem value={3}>Completed</MenuItem>
                          </CustomSelect>
                          {selecteditems.length > 0 && (
                            <>
                              <button
                                onClick={(event) => {
                                  handleMintModalShow(data);
                                }}
                                className=" px-4 py-2 text-sm font-medium leading-5 text-white content-button status-button"
                              >
                                Mint Now
                              </button>
                              <button
                                className=" icon-rounded text-sm font-medium leading-5 del-nft"
                                aria-label="Delete"
                                disabled={
                                  props.deleteSingleNFT.buttonDisabled &&
                                  data.nft_image_id == deleteNFT
                                }
                                onClick={() =>
                                  handleNFTDelete(
                                    selecteditems.map((selected, index) => {
                                      return selected.nft_image_id;
                                    })
                                  )
                                }
                              >
                                <svg
                                  fill="#fff"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  width="24"
                                >
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                </svg>
                              </button>
                            </>
                          )}
                        </div>

                        <div className="app-card-buttons">
                          <Link
                            to={"/add-nft-image"}
                            className=" content-button status-button"
                          >
                            Add NFT
                          </Link>
                        </div>
                      </div>
                      {selecteditems.length > 0 && (
                        <>
                          <div className="p-3">
                            <Alert
                              variant={"dark"}
                              className="text-center light-grey-alert"
                            >
                              All <strong>{selecteditems.length}</strong> images
                              on this page are selected.
                            </Alert>
                          </div>
                        </>
                      )}
                      <div className="w-full overflow-x-auto nft-table">
                        <table className="w-full whitespace-no-wrap">
                          <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left table-content-head">
                              <th>
                                {props.listOFNFT.data.length > 0 && (
                                  <div className="select-checkbox">
                                    <Checkbox
                                      onChange={handleBulkItemSelect}
                                      checked={selecteditems.length > 0}
                                    />
                                  </div>
                                )}
                              </th>
                              <th className="px-4 py-3">S.No</th>
                              <th className="px-4 py-3">NFT Image</th>
                              <th className="px-4 py-3">NFT Name</th>
                              <th className="px-4 py-3">Status</th>
                              <th className="px-4 py-3">Active Status</th>
                              <th className="px-4 py-3">Action</th>
                            </tr>
                          </thead>
                          <tbody className="  ">
                            {props.listOFNFT.data.length > 0 ? (
                              props.listOFNFT.data
                                // .slice(offset, offset + PER_PAGE)
                                .map((data, index) => (
                                  <tr
                                    className={`nft-list-border ${data.status == 3
                                      ? "list-completed-image-bg"
                                      : ""
                                      }`}
                                    key={index}
                                  >
                                    <td>
                                      <Checkbox
                                        onChange={() =>
                                          handleSingleItemSelect(data)
                                        }
                                        checked={
                                          selecteditems.filter(
                                            (selected) =>
                                              selected.nft_image_id ==
                                              data.nft_image_id
                                          ).length > 0
                                            ? true
                                            : false
                                        }
                                      />
                                    </td>
                                    <td className="px-4 py-3">
                                      {index + 1 * offset + 1}
                                    </td>
                                    <td className="px-4 py-3">
                                      <div className="flex items-center text-sm">
                                        <div className="relative nft-list rounded-lg block">
                                          <Link
                                            to={`/single-nft/${data.nft_image_unique_id}/${data.nft_image_id}`}
                                          >
                                            <img
                                              className="image"
                                              src={data.picture}
                                              alt=""
                                              loading="lazy"
                                            />
                                            <div
                                              className="absolute inset-0 rounded-full "
                                              aria-hidden="true"
                                            ></div>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                      <div>
                                        <Link
                                          className="font-semibold"
                                          to={`/single-nft/${data.nft_image_unique_id}/${data.nft_image_id}`}
                                        >
                                          {data.name}
                                        </Link>
                                      </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                      {data.status == 1 ? (
                                        <span className="p-3 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                          {data.status_formatted}
                                        </span>
                                      ) : (
                                        <span className="p-3 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:bg-red-700 dark:text-red-100">
                                          {data.status_formatted}
                                        </span>
                                      )}
                                    </td>
                                    <td className="px-4 py-3 text-xs">
                                      {data.active_status == 1 ? (
                                        <span className="p-3 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                          {data.active_status_formatted}
                                        </span>
                                      ) : (
                                        <span className="p-3 font-semibold leading-tight text-red-700 bg-red-100 rounded-full dark:bg-red-700 dark:text-red-100">
                                          {data.active_status_formatted}
                                        </span>
                                      )}
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                      <div className="nft-action-btn">
                                        <Link
                                          to={`/single-nft/${data.nft_image_unique_id}/${data.nft_image_id}`}
                                          className="icon-rounded  text-sm font-medium leading-5 text-white"
                                        >
                                          <i className="fas fa-eye"></i>
                                        </Link>
                                        <Link
                                          to={`/edit-nft/${data.nft_image_unique_id}/${data.nft_image_id}`}
                                          className=" icon-rounded  text-sm font-medium leading-5 text-white"
                                        >
                                          <i className="fas fa-pen"></i>
                                        </Link>
                                        {data.is_enable_btn == 1 && (
                                          <button
                                            onClick={(event) => {
                                              handleMintModalShow(data);
                                            }}
                                            className=" px-4 py-2 text-sm font-medium leading-5 text-white content-button status-button"
                                          >
                                            Mint Now
                                          </button>
                                        )}
                                        <button
                                          className=" icon-rounded text-sm font-medium leading-5 del-nft"
                                          aria-label="Delete"
                                          disabled={
                                            props.deleteSingleNFT
                                              .buttonDisabled &&
                                            data.nft_image_id == deleteNFT
                                          }
                                          onClick={() =>
                                            handleNFTDelete(data.nft_image_id)
                                          }
                                        >
                                          <svg
                                            fill="#fff"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                          >
                                            <path
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                          </svg>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <p>No Data Found</p>
                            )}
                          </tbody>
                        </table>
                        {/* <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}

                          <TableCell>
                            {props.listOFNFT.data.length > 0 && (
                              <div className="select-checkbox">
                                <Checkbox
                                  onChange={handleBulkItemSelect}
                                  checked={selecteditems.length > 0}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="px-4 py-3">S.No</TableCell>
                          <TableCell className="px-4 py-3">NFT Image</TableCell>
                          <TableCell className="px-4 py-3">NFT Name</TableCell>
                          <TableCell className="px-4 py-3">Status</TableCell>
                          <TableCell className="px-4 py-3">
                            Active Status
                          </TableCell>
                          <TableCell className="px-4 py-3">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody></TableBody>
                    </Table>
                  </TableContainer> */}
                      </div>
                      <div className="list_of_nft_pagination_wrapper">
                        <span className="flex items-center">
                          Showing {currentPage * PER_PAGE + 1} -{" "}
                          {sortby ? (
                            <>{props.listOFNFT.data.length} </>
                          ) : (
                            <>
                              {currentPage == 0
                                ? PER_PAGE
                                : offset + PER_PAGE > props.listOFNFT.total
                                  ? props.listOFNFT.total
                                  : offset + PER_PAGE}{" "}
                            </>
                          )}
                          of{" "}
                          {sortby
                            ? props.listOFNFT.data.length
                            : props.listOFNFT.total}
                        </span>
                        <ReactPaginate
                          previousLabel={
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 fill-current"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                                fill-rule="evenodd"
                              ></path>
                            </svg>
                          }
                          nextLabel={
                            <svg
                              className="w-4 h-4 fill-current"
                              aria-hidden="true"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                                fill-rule="evenodd"
                              ></path>
                            </svg>
                          }
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"list_of_nft_pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <MintNowModal
        show={mintNowModal}
        handleClose={handleMintNowModalClose}
        minterWalletAddress={minterWalletAddress}
        setMinterWalletAdddress={setMinterWalletAdddress}
        mintYourNFT={mintYourNFT}
        mintButtonContent={mintButtonContent}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  listOFNFT: state.nft.listOFNFT,
  deleteSingleNFT: state.nft.deleteSingleNFT,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ListOfNFT);
