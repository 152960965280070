export const apiConstants = {
  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: "https://cms-ktmnftmint.blockstall.com/api/user/get_settings_json",

  chest: ["Ring Mail", "Big Chest", "Powerful chest", "Biggest chest"],

  foot: ["foot", "normal foot", "black shoe", "white shoe"],

  hand: ["normal hand", "big hand", "small hand", "powerfull hand"],

  head: ["good helmet", "silver helmet", "cap", "golden cap"],

  min: 1,

  max: 4,

  ipfs_project_id: "2Hhvjj02h7GRo3wpMXFgA8ZhrIf",

  ipfs_project_secret: "df10adbcd1995dfb5da43a2e9a8aa6c1"
};
